<template>
    <AdminAPISaveComponent/>
  </template>
  
  <script>
  import AdminAPISaveComponent from '../../components/admin/AdminAPISaveComponent.vue'
  
  export default {
    name: 'AdminAPISaveView',
  
    components: {
        AdminAPISaveComponent,
    },
    
  }
  </script>