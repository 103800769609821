<template>
    <v-card elevation="2" outlined shaped>
        <v-select v-model="anyo" :items="itemsAnyo" outlined @change="loadOnChange($event)" hint="Selecciona Año"
            persistent-hint>
            <template v-slot:selection="{ item }">
                <span class="d-flex justify-center" style="width: 100%;">
                    {{ item }}
                </span>
            </template>
        </v-select>
        <Bar id="chart-beneficios" v-if="loaded" :data="chartData" :style="myStyles" />
    </v-card>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import UserService from '../../services/user.service';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'ChartComponent',
    components: {
        Bar
    },
    data() {
        return {
            idBot: '',
            isAdmin: false,
            loaded: true,
            anyo: '',
            itemsAnyo: [2024, 2025],
            totalBeneficios: '',
            chartData: {
                labels: ['Enero', 'Febrero', 'Marzo', 'Abril',
                    'Mayo', 'Junio', 'Julio', 'Agosto',
                    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                datasets: [{
                    label: 'Ganancias Mensuales',
                    data: [],
                    backgroundColor: '#ffffff',
                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    computed: {
        myStyles() {
            return {
                height: '${/* mutable height */}px',
                position: 'relative'
            }
        },

    },
    created() {
        this.anyo = this.itemsAnyo[1]
    },
    methods: {
        loadDataSets(value, isAdmin) {
            this.idBot = value
            this.isAdmin = isAdmin
            this.chartData.datasets[0].data = []
            this.loaded = false

            if (!this.isAdmin) {
                this.loadUserChart(this.idBot)
            } else {
                this.loadAdminChart(this.idBot)
            }

        },
        loadOnChange(value) {
            this.anyo = value
            this.chartData.datasets[0].data = []
            this.loaded = false
            if (!this.isAdmin) {
                this.loadUserChart(this.idBot)
            } else {
                this.loadAdminChart(this.idBot)
            }
        },
        loadUserChart(value) {
            UserService.getChartBot(value, this.anyo).then(
                response => {
                    this.totalBeneficios = response.data;
                    for (let index = 0; index < this.totalBeneficios.length; index++) {
                        this.chartData.datasets[0].data.push(this.totalBeneficios[index].beneficios)
                    }
                    this.loaded = true
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    // EventBus.dispatch("logout");
                }
            );
        },
        loadAdminChart(value) {
            UserService.getAdminChartBot(value, this.anyo).then(
                response => {
                    this.totalBeneficios = response.data;
                    for (let index = 0; index < this.totalBeneficios.length; index++) {
                        this.chartData.datasets[0].data.push(this.totalBeneficios[index].beneficios)
                    }
                    this.loaded = true
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    // EventBus.dispatch("logout");
                }
            );
        }
    }
}
</script>