<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
                                        <v-card elevation="2" outlined shaped>
                                            <v-row no-gutters justify="center">
                                                <v-card-title class="text-center">Suscripción {{ this.estado
                                                    }}</v-card-title>
                                                <br>
                                            </v-row>
                                            <br>
                                        </v-card>
                                    </v-row>
                                </v-card>
                                <v-card>
                                    <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
                                        <!-- <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0">
                                        </v-col> -->
                                        <v-col cols="12" xs="12" sm="12" md="12">
                                            <v-card elevation="2" outlined shaped>
                                                <v-row no-gutters>
                                                    <v-col cols="1" xs="0" sm="0" md="1"
                                                        class="flex-grow-0 flex-shrink-0"></v-col>
                                                    <v-col cols="10" xs="12" sm="12" md="10"
                                                        class="flex-grow-0 flex-shrink-0">
                                                        <v-row no-gutters justify="center">
                                                            <v-card-title
                                                                class="text-h5 text-center">BOTS</v-card-title>
                                                        </v-row>
                                                    </v-col>
                                                    <br>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="1" xs="0" sm="0" md="1"
                                                        class="flex-grow-0 flex-shrink-0"></v-col>
                                                    <v-col cols="10" xs="12" sm="12" md="10"
                                                        class="flex-grow-0 flex-shrink-0">
                                                        <v-row no-gutters justify="center">
                                                            <v-data-table :headers="headersCart" :items="itemsCart"
                                                                :search="search">
                                                                <template v-slot:[`item.actions`]="{ item }">
                                                                    <div v-if="!item.bot">
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-icon small class="mr-2" dark
                                                                                    v-on="on"
                                                                                    @click="handleClick(item, 'addBot')">mdi-robot</v-icon>
                                                                            </template>
                                                                            <span>Agregar Bot</span>
                                                                        </v-tooltip>
                                                                    </div>

                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" dark v-on="on"
                                                                                @click="handleClick(item, 'pay')">mdi-wallet</v-icon>
                                                                        </template>
                                                                        <span>Pagar</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon small class="mr-2" dark v-on="on"
                                                                                @click="handleClick(item, 'cancel')">mdi-delete</v-icon>
                                                                        </template>
                                                                        <span>Cancelar</span>
                                                                    </v-tooltip>
                                                                </template>
                                                            </v-data-table>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <br>
                                                <v-row no-gutters justify="center">
                                                    <!-- <v-btn :disabled="invalid" color="success"
                                                        @click="menuActionClick('newBot')">
                                                        Agregar Bot
                                                    </v-btn> -->

                                                    <div v-if="masSuscripcion">
                                                        <v-btn color="success" @click="menuActionClick('renovarTodo')">
                                                            Pagar Todo
                                                        </v-btn>
                                                    </div>
                                                </v-row>
                                                <br>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="10" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                                            <v-data-table :headers="headersPays" :items="itemsPays"
                                                :search="search"></v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="3" xs="0" sm="0" md="5"> </v-col>
                            <v-col cols="6" xs="2" sm="2" md="2">
                                <v-row no-gutters justify="center">
                                    <v-btn color="blue-grey" @click="comprarBot" :disabled="noMasBots">
                                        COMPRAR BOT
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                    </v-card>
                </v-col>
            </v-row>
            <div class="w-auto" v-show="isOpenDialog">
                <DialogQuestions ref="dialogQuestions" @deleteSuscripcion="cancelSuscripcion" />
            </div>
            <div class="w-auto" v-show="isOpenDialog">
                <DialogMessages ref="dialogMessages" @cancel="closeAlert" />
            </div>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus"
import UserService from '../../services/user.service'
import Type from '../../models/type'
import DialogQuestions from '@/components/utils/DialogQuestionComponent.vue'
import DialogMessages from '@/components/utils/DialogMessagesComponent.vue'

export default {
    name: 'CartComponent',
    components: {
        DialogQuestions,
        DialogMessages
    },
    data() {
        return {
            // Variables Pantalla
            title: "MI CUENTA",
            idSuscripcion: '',
            estado: '',
            search: '',
            content: '',
            // Objetos
            type: new Type('', ''),
            // Condicionales
            invalid: true,
            masSuscripcion: false,
            noMasBots: false,
            diasRestantes: '',
            successful: false,
            isOpenDialog: false,
            // Table Cart
            headersCart: [
                { text: "Nombre", value: "tipoSuscripcion.nombre", align: "center" },
                { text: "Descripcion", value: "tipoSuscripcion.descripcion", align: "center" },
                { text: "Limite Bot", value: "tipoSuscripcion.limiteInversionPorBot", align: "center" },
                { text: "Par", value: "bot.pair", align: "center" },
                { text: "Iniciado", value: "bot.started", align: "center" },
                { text: "Fecha Fin", value: "fechaFin", align: "center" },
                { text: 'Acciones', value: 'actions', sortable: false, align: "center" }
            ],
            itemsCart: [],
            // Table bots
            headersBots: [
                { text: "Par", value: "pair", align: "center" },
                { text: "Cantidad por Trade", value: "quantity", align: "center" },
                { text: "Cantidad Bot", value: "amount", align: "center" },
                { text: "En marcha", value: "started", align: "center" },
                { text: "Porcentaje", value: "percent", align: "center" },
                { text: 'Acciones', value: 'actions', sortable: false, align: "center" }
            ],
            itemsBots: [],
            // Table Pays
            headersPays: [
                { text: "Fecha Pago", value: "fechaPago", align: "center" },
                { text: "Metodo Pago", value: "metodoPago", align: "center" },
                { text: "Precio", value: "monto", align: "center" },
                { text: "Estado Pago", value: "estadoPago", align: "center" },
            ],
            itemsPays: []
        };
    },
    computed: {},
    mounted() {
        UserService.getCart().then(
            response => {
                this.diasRestantes = response.data.diasRestantes
                if (response.data.estado !== 'false') {
                    this.estado = 'ACTIVADA'
                } else {
                    this.estado = 'FINALIZADA'
                }
                if (response.data.botsDisponibles) {
                    this.invalid = false
                } else {
                    this.invalid = true
                }
                if (response.data.listSuscripcion.length > 1 && this.diasRestantes < 15) {
                    this.masSuscripcion = true
                }
                if (response.data.listSuscripcion.length === 5) {
                    this.noMasBots = true
                }
                this.itemsCart = response.data.listSuscripcion
                this.itemsPays = response.data.listPago

            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    methods: {
        menuActionClick(action) {
            // if (action === "newBot") {
            //     this.$router.push('/bot_save');
            // }
            if (action === "renovarTodo") {

                this.$router.push("/menu_suscripcion/?accion=update&idSuscripcion=all");
            }
        },
        handleClick(value, action) {
            switch (action) {
                case 'addBot':
                    if (value.bot === null) {
                        this.$router.push('/bot_save/?idSuscripcion=' + value.id)
                    }
                    break;
                case 'pay':
                    this.checkPeriodBot(value)
                    break;
                case 'cancel':
                    this.idSuscripcion = value.id
                    this.openDialogQuestion()
                    break;
            }
        },
        checkPeriodBot(value) {
            UserService.chekPeriodBot(value.id).then(
                response => {
                    if (response) {
                        if (response.data > 15) {
                            this.msgError = 'Todavia le faltan ' + response.data + ' días para que finalice la suscripcón. '
                                + 'Si quiere renovar la suscripción, abra un ticket'
                            this.types = 'success'
                            this.$refs.dialogMessages.openModal()
                            this.$refs.dialogMessages.tituloDialog = 'ACTUALIZACIÓN DE SUSCRIPCIÓN'
                            this.$refs.dialogMessages.msgError = this.msgError
                            this.$refs.dialogMessages.types = this.types
                            this.$refs.dialogMessages.isShow = true
                        } else {
                            this.$router.push("/menu_suscripcion/?accion=update&idSuscripcion=" + value.id)
                            // ?suscripcion=" + this.idSuscripcion + '&tipo=' + this.selected.id
                        }
                    }
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout")
                    }
                }
            );
        },
        openDialogQuestion() {
            this.$refs.dialogQuestions.openModal()
            this.$refs.dialogQuestions.component = 'DELETE_SUSCRIPCION'
            this.$refs.dialogQuestions.tituloDialog = 'Cancelar Suscripcion'
            this.$refs.dialogQuestions.question = '¿ Desea realmente cancelar la suscripción ?'
        },
        cancelSuscripcion() {
            UserService.cancelarSuscripcion(this.idSuscripcion).then(
                response => {
                    setTimeout(() => (this.responseCancel('success', response.data)), 2000)
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setTimeout(() => (this.responseCancel('error', error.response.data)), 2000)
                }
            );
        },
        responseCancel(response, msg) {
            this.$refs.dialogMessages.openModal()
            this.$refs.dialogMessages.component = 'CANCELAR SUSCRIPCION'
            this.$refs.dialogMessages.tituloDialog = 'CANCELAR SUSCRIPCION'
            this.$refs.dialogMessages.msgError = msg
            this.$refs.dialogMessages.isShow = true
            if (response === 'success') {
                this.$refs.dialogMessages.types = 'success'
                this.successful = true
            } else {
                this.$refs.dialogMessages.types = 'error'
                this.successful = false
            }
        },
        comprarBot() {
            this.$router.push("/menu_suscripcion/?accion=new")
        },
        closeAlert() {
            if (this.successful) {
                this.$router.go()
            }
        }
    }
}
</script>