<template>
    <v-card>
        <v-list>
            <v-list-item link>
                <v-list-item-content>
                    <v-list-item-title class="text-h6" v-if="content.name != null">
                        {{ content.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="content.email != null">
                        {{ content.email }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list-item link @click="$router.push('/home').catch(err => { })">
            <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$router.push('/profile').catch(err => { })">
            <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Perfil</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$router.push('/cart').catch(err => { })">
            <v-list-item-icon>
                <v-icon>mdi-ticket-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mi Cuenta</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$router.push('/tickets').catch(err => { })">
            <v-list-item-icon>
                <v-icon>mdi-sticker-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tickets</v-list-item-title>
        </v-list-item>
        <div v-if="this.active">
            <v-list-item link @click="$router.push('/client').catch(err => { })">
                <v-list-item-icon>
                    <v-icon>mdi-api</v-icon>
                </v-list-item-icon>
                <v-list-item-title>APIs</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push('/wallets').catch(err => { })">
                <v-list-item-icon>
                    <v-icon>mdi-wallet-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Wallets</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push('/bot').catch(err => { })">
                <v-list-item-icon>
                    <v-icon>mdi-robot-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Bot</v-list-item-title>
            </v-list-item>
        </div>

        <v-list-item link href @click.prevent="logOut">
            <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Salir</v-list-item-title>
        </v-list-item>
    </v-card>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import TokenService from '@/services/token.service';
import User from '../../models/user';

export default {
    name: 'NavigationComponent',
    mounted() {
        this.loadData()
    },
    created() { },
    computed: {},
    methods: {
        logOut() {
            this.$emit('logout');
        },
        loadData() {
            if (this.$store.state.auth.status.loggedIn === true) {
                if (TokenService.getUser() !== null) {
                    UserService.getUserProfile().then(
                        response => {
                            this.user.email = response.data.email;
                            this.user.username = response.data.username;
                            this.content = response.data
                            this.name = this.content.name
                            this.lastName = this.content.lastName
                            this.email = this.content.email
                            this.phone = this.content.phone
                            this.active = this.content.isCurrentPay
                        },
                        error => {
                            this.content =
                                (error.response && error.response.data && error.response.data.message) ||
                                error.message ||
                                error.toString();
                            if (error.response && error.response.status === 403) {
                                EventBus.dispatch("logout");
                            }
                        }
                    );
                }

            }
        }
    },
    beforeDestroy() {
        EventBus.remove("logout");
    },
    data() {
        return {
            items: [
                { title: "Login", icon: "mdi-view-dashboard", route: "/" },
                { title: "Photos", icon: "mdi-image" },
                { title: "About", icon: "mdi-help-box" }
            ],
            right: null,
            user: new User('', '', ''),
            content: '',
            active: false
        };
    }

}
</script>