<template>
    <AdminAPIComponent/>
  </template>
  
  <script>
  import AdminAPIComponent from '../../components/admin/AdminAPISComponent.vue'
  
  export default {
    name: 'AdminAPIView',
  
    components: {
        AdminAPIComponent,
    },
    
  }
  </script>