import Vue from 'vue'
import VueRouter from 'vue-router'
// PUBLIC
import IndexView from '../views/public/IndexView.vue'
import LoginView from '../views/public/LoginView.vue'
import RegisterView from '../views/public/RegisterView.vue'
import FormNewUserView from '../views/public/FormNewUserView.vue'
import VerifyView from '../views/public/VerifyView.vue'
import TermsView from '../views/public/TermsView.vue'
// USER
import HomeView from '../views/user/HomeView.vue'
import ProfileView from '../views/user/ProfileView.vue'
import ClientView from '../views/user/ClientView.vue'
import ClientSaveView from '../views/user/ClientSaveView.vue'
import WalletsView from '../views/user/WalletsView.vue'
import BotView from '../views/user/BotView.vue'
import BotSaveView from '../views/user/BotSaveView.vue'
import OrdersView from '../views/user/OrdersView.vue'
import CartView from '../views/user/CartView.vue'
import SuscripcionView from '../views/user/SuscripcionView.vue'
import SuscripcionMenuView from '../views/user/SuscripcionMenuView.vue'
// AMAZON
import AmazonCheckoutView from '../views/amazon/AmazonCheckoutView.vue'
import AmazonResultView from '../views/amazon/AmazonResultView.vue'
import CriptoResultView from '../views/cripto/CriptoResultView.vue'

import TicketView from '../views/user/TicketView.vue'
import TicketSaveView from '../views/user/TicketSaveView.vue'

import TronView from '../views/user/TronView.vue'
// ADMIN
import AdminHomeView from '../views/admin/AdminHomeView.vue'
import AdminUsersView from '../views/admin/AdminUsersView.vue'
import AdminUserView from '../views/admin/AdminUserView.vue'
import AdminUserNewView from '../views/admin/AdminUserNewView.vue'
import AdminAPISView from '../views/admin/AdminAPISView.vue'
import AdminAPISaveView from '../views/admin/AdminAPISaveView.vue'
import AdminBotsView from '../views/admin/AdminBotsView.vue'
import AdminTradesView from '../views/admin/AdminTradesView.vue'
import AdminSubsView from '../views/admin/AdminSubsView.vue'
import AdminSubView from '../views/admin/AdminSubView.vue'
import AdminComercialesView from '../views/admin/AdminComercialesView.vue'
import AdminComercialSaveView from '../views/admin/AdminComercialSaveView.vue'
import AdminPagosView from '@/views/admin/AdminPagosView.vue'
import AdminPagosSaveView from '@/views/admin/AdminPagosSaveView.vue'
import AdminTicketsView from '@/views/admin/AdminTicketsView.vue'

Vue.use(VueRouter)

const routes = [
  {
    // /search/screens -> /search?q=screens
    path: '/',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/index/:data?', query: { q: to.params.searchText } }
    },
  },
  {
    path: '/tron/:suscripciones?',
    name: 'tron',
    component: TronView
  },
  {
    path: '/index/:data?',
    name: 'index',
    component: IndexView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register/:referido?',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/form_new_user/:referido?',
    name: 'formNewUser',
    component: FormNewUserView
  },
  {
    path: '/verify/:verify',
    name: 'verifyView',
    component: VerifyView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/cart/:idUser?',
    name: 'cart',
    component: CartView
  },
  {
    path: '/suscripcion/',
    name: 'suscripcion',
    component: SuscripcionView
  },
  {
    path: '/menu_suscripcion/:idSuscripcion?',
    name: 'suscripcionMenu',
    component: SuscripcionMenuView
  },
  {
    path: '/checkout/:amazonCheckoutSessionId?',
    name: 'checkout',
    component: AmazonCheckoutView
  },
  {
    path: '/result_pay/:amazonCheckoutSessionId?',
    name: 'result_pay',
    component: AmazonResultView
  },
  {
    path: '/result_cripto/:criptoParams?',
    name: 'result_cripto',
    component: CriptoResultView
  },
  {
    path: '/orders/:idBot?',
    name: 'orders',
    component: OrdersView
  },
  {
    path: '/client',
    name: 'client',
    component: ClientView
  },
  {
    path: '/client_save/:idClient?',
    name: 'client_save',
    component: ClientSaveView
  },
  {
    path: '/wallets',
    name: 'wallets',
    component: WalletsView
  },
  {
    path: '/bot',
    name: 'bot',
    component: BotView
  },
  {
    path: '/bot_save/:idBot?',
    name: 'bot_save',
    component: BotSaveView
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: TicketView
  },
  {
    path: '/ticket_save',
    name: 'ticket_save',
    component: TicketSaveView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/public/AboutView.vue')
  },

  // ADMIN
  {
    path: '/admin_home',
    name: 'adminHome',
    component: AdminHomeView
  },
  {
    path: '/admin_users',
    name: 'adminUsers',
    component: AdminUsersView
  },
  {
    path: '/admin_user/:idUser?',
    name: 'adminUser',
    component: AdminUserView
  },
  {
    path: '/admin_user_new',
    name: 'adminUserNew',
    component: AdminUserNewView
  },
  {
    path: '/admin_apis',
    name: 'adminAPIS',
    component: AdminAPISView
  },
  {
    path: '/admin_api_save/:idAPI?',
    name: 'adminAPIS',
    component: AdminAPISaveView
  },
  {
    path: '/admin_bots',
    name: 'adminBots',
    component: AdminBotsView
  },
  {
    path: '/admin_trades/:idBot?',
    name: 'adminTrades',
    component: AdminTradesView
  },
  {
    path: '/admin_subs',
    name: 'adminSubs',
    component: AdminSubsView
  },
  {
    path: '/admin_sub/:idSub?',
    name: 'adminSub',
    component: AdminSubView
  },
  {
    path: '/admin_comerciales',
    name: 'adminComerciales',
    component: AdminComercialesView
  },
  {
    path: '/admin_comercial_save/:idComercial?',
    name: 'adminComercialSave',
    component: AdminComercialSaveView
  },
  {
    path: '/admin_pagos',
    name: 'adminPagos',
    component: AdminPagosView
  },
  {
    path: '/admin_pagos_save/:idPay?',
    name: 'adminPagosSave',
    component: AdminPagosSaveView
  },
  {
    path: '/admin_tickets',
    name: 'adminTickets',
    component: AdminTicketsView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // mode: 'abstract'
})

export default router
