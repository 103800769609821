<template>
  <v-container class="bg-surface-variant">
    <div class="d-flex flex-column mb-6 bg-surface-variant">
      <v-row no-gutters style="flex-wrap: nowrap;">
        <v-col>
          <v-card elevation="2" outlined shaped>
            <!-- <v-row justify="center">
              <v-card-title class="text-h5">{{ titleOrders }}</v-card-title>
            </v-row> -->
            <OrdersDataComponent ref="ordersData" />
            <!--<v-row no-gutters>
              <v-col cols="0" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
              <v-col cols="12" xs="0" sm="0" md="8" class="flex-grow-0 flex-shrink-0">
                <v-card elevation="2" outlined shaped>
                  <br>
                  
                   <v-row no-gutters>

                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Fecha Inicio:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ initDate }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-btn :color="colors" @click="statusBot">{{ msgBtn }}</v-btn>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br>
                  <vDivider></vDivider>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Precio Actual:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ currentPrice }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Saldo Bot:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ available }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br>
                  <vDivider></vDivider>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Beneficios:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ profits }}</v-list-item-title>
                        </v-card>
                      </v-row>
                    </v-col>
                    <v-col cols="4" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <v-list-item-title class="text-center">Comisiones:</v-list-item-title>
                          <v-list-item-title class="text-center">{{ fees }}</v-list-item-title>
                        </v-card>
                      </v-row>

                    </v-col>
                  </v-row> 
                  <br>
                  <vDivider></vDivider>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="1" xs="0" sm="0" md="1" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="10" xs="0" sm="0" md="10" class="flex-grow-0 flex-shrink-0">
                      <v-row no-gutters justify="center">
                        <v-card>
                          <ChartComponent></ChartComponent>
                        </v-card>
                      </v-row>
                    </v-col>
                  </v-row>
                  <br>
                </v-card>
              </v-col>
            </v-row>-->
            <vDivider></vDivider>
            <br>
            <v-row no-gutters>
              <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
              <v-col cols="8" xs="12" sm="12" md="8" class="flex-grow-0 flex-shrink-0">
                <v-card class="pa-1">
                  <v-data-table :headers="headers" :items="itemsWithIndex" :search="search"
                    class="elevation-1"></v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Bot from "../../models/bot";
import UserService from '../../services/user.service';
import EventBus from "../../common/EventBus";
import OrdersDataComponent from '../utils/OrdersDataComponent.vue'

export default {
  name: 'OrdersComponent',
  components: {
    OrdersDataComponent
  },
  data() {
    return {
      idBot: '',
      bot: new Bot(),
      // Table
      headers: [
        { text: "Tipo", value: "typeCompra", align: "center" },
        { text: "Fecha Compra", value: "fechaCompra", align: "center" },
        // { text: "Cantidad Comprado", value: "amountCompra", align: "center" },
        { text: "Precio Compra", value: "precioCompra", align: "center" },
        { text: "Tipo", value: "typeVenta", align: "center" },
        { text: "Fecha Venta", value: "fechaVenta", align: "center" },
        // { text: "Cantidad Vendido", value: "amountVenta", align: "center" },
        { text: "Precio Venta", value: "precioVenta", align: "center" },
        { text: "Volumen", value: "amountCompra", align: "center" },
      ],
      items: [],
      // right: null,
      search: '',
      colors: "success",
      msgBtn: ''
    };
  },
  computed: {
    itemsWithIndex() {
      return this.items.map((item, index) => ({ ...item, index: index }))
    }
  },
  created() { },
  mounted() {
    // Declare a variable
    let isUndefined;
    // Condition to check variable is defined or not
    if (typeof this.$route.params.idBot === "undefined") {
      isUndefined = true;
    } else {
      isUndefined = false;
    }
    if (this.$route.params.idBot !== null && !isUndefined) {
      this.idBot = this.$route.params.idBot
      this.$refs.ordersData.initData(this.idBot, false)
      this.getOrders()
    }

  },
  methods: {
    getOrders() {
      UserService.getTradesBot(this.idBot).then(
        response => {
          this.items = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
  }
}
</script>

<style scoped>
.mytable .v-table tbody tr:not(:last-child) {
  border-bottom: none;
  background-color: blue;
}

.compraClass tbody tr {
  background-color: red !important;
  color: red;
}

.ventaClass {
  background-color: green
}
</style>
