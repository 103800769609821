<template>
  <v-container class="bg-surface-variant">
    <div class="d-flex flex-column mb-6 bg-surface-variant">
      <v-row class="text-center">
        <v-col cols="12">
          <!-- <v-img :src="require('../../assets/logo_vue.svg')" class="my-3" contain height="200" /> -->
          <v-img :src="require('../../assets/logo.png')" class="my-3" contain height="200" />
        </v-col>
        <br>
        <v-row class="text-center">
          <v-col cols="12">
            <br>
            <v-card elevation="2" outlined shaped>
              <v-row class="text-center">
                <v-col cols="12" class="mb-4">
                  <br>
                  <h1 class="display-2 font-weight-bold mb-3">
                    {{ username }}
                  </h1>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <div v-if="!isMobile()">
                <v-row class="text-center">
                  <v-col cols="6" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <br>
                      <h3> {{ estado }} </h3>
                      <p class="subheading font-weight-regular">
                        <br>Suscripciones activas: {{ suscripcionesActivas }}
                        <br>Bots creados: {{ botsCreated }}
                        <br>Fin suscripción: {{ endSubs }}
                      </p>
                      <!-- <a href="https://commerce.coinbase.com/checkout/28f1d543-48bc-4288-9ea5-db20dcd48426">
                        <span>Pay with crypto</span>
                      </a> -->
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <v-row>
                        <v-col cols="2" class="mb-4"></v-col>
                        <v-col cols="3" class="mb-4">
                          <v-select v-model="anyo" :items="itemsAnyo" outlined @change="loadChart($event)"
                            hint="Selecciona Año" persistent-hint>
                            <template v-slot:selection="{ item }">
                              <span class="d-flex justify-center" style="width: 100%;">
                                {{ item }}
                              </span>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="2" class="mb-4"></v-col>
                        <v-col cols="3" class="mb-4">
                          <br>TOTAL: {{ totalBeneficios }}
                        </v-col>
                      </v-row>
                      <Bar id="my-chart-id" v-if="loaded" :data="chartData" :style="myStyles" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row class="text-center">
                  <v-col cols="12" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <br>
                      <h3> {{ estado }} </h3>
                      <p class="subheading font-weight-regular">
                        <br>Suscripciones activas: {{ suscripcionesActivas }}
                        <br>Bots creados: {{ botsCreated }}
                        <br>Fin suscripción: {{ endSubs }}
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col cols="12" class="mb-2">
                    <v-card elevation="2" outlined shaped>
                      <v-row>
                        <v-col cols="2" class="mb-4"></v-col>
                        <v-col cols="3" class="mb-4">
                          <v-select v-model="anyo" :items="itemsAnyo" outlined @change="loadChart($event)"
                            hint="Selecciona Año" persistent-hint>
                            <template v-slot:selection="{ item }">
                              <span class="d-flex justify-center" style="width: 100%;">
                                {{ item }}
                              </span>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="2" class="mb-4"></v-col>
                        <v-col cols="3" class="mb-4">
                          <br>TOTAL: {{ totalBeneficios }}
                        </v-col>
                      </v-row>
                      <Bar id="my-chart-id" v-if="loaded" :data="chartData" :style="myStyles" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import User from '../../models/user';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'HomeComponent',
  components: {
    Bar,
    // ButtonAmazon
  },
  data() {
    return {
      title: 'HOME',
      user: new User('', '', ''),
      estado: '',
      suscripcionesActivas: '',
      content: '',
      username: '',
      botsCreated: '',
      endSubs: '',
      totalInvertido: '',
      chartBeneficios: '',
      totalComisiones: '',
      totalBeneficios: '',
      message: '',
      // Chart
      anyo: '',
      itemsAnyo: [2024, 2025],
      loaded: false,
      chartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril',
          'Mayo', 'Junio', 'Julio', 'Agosto',
          'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [{
          label: 'Ganancias Mensuales',
          data: [],
          backgroundColor: '#ffffff',
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
    };
  },
  created() { },
  computed: {
    currentUser() {
      return this.user;
    },
    myStyles() {
      return {
        height: '${/* mutable height */}px',
        position: 'relative'
      }
    }
  },
  mounted() {
    this.anyo = this.itemsAnyo[1]
    this.loaded = false
    UserService.getHomeInfo().then(
      response => {
        // console.log(response)
        if (response.data.activado) {
          this.estado = 'ACTIVADO'
        } else {
          this.estado = 'NO ACTIVADO'
        }
        this.username = response.data.userName
        this.suscripcionesActivas = response.data.suscripcionesActivas
        this.botsCreated = response.data.botsCreated
        this.endSubs = response.data.end_time
        this.totalInvertido = response.data.totalInvertido

      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout")
        }
      }
    );
    this.loadChart(this.anyo)
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    loadChart(value) {
      // console.log(value)
      this.chartData.datasets[0].data = [];
      this.loaded = false
      UserService.getChartInfo(value).then(
        response => {
          // console.log(response.data)
          this.chartBeneficios = response.data.beneficios
          this.totalComisiones = response.data.comisiones
          this.totalBeneficios = response.data.totalBeneficios
          // console.log(this.chartBeneficios)
          if (this.chartBeneficios !== null && this.chartBeneficios !== undefined) {
            for (let index = 0; index < this.chartBeneficios.length; index++) {
              this.chartData.datasets[0].data.push(this.chartBeneficios[index].beneficios)
            }
            this.loaded = true
          }

        },
        error => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  }
}
</script>

<style>
.v-select {
  width: 100%;
  justify-content: center;
}
</style>