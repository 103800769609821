<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="8" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-data-table :headers="headers" :items="itemsTable" :search="search">
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" dark v-on="on"
                                                    @click="handleClick(item, 'orders')">mdi-play</v-icon>
                                            </template>
                                            <span>Ordenes</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" dark v-on="on"
                                                    @click="handleClick(item, 'update')">mdi-content-save-edit-outline</v-icon>
                                            </template>
                                            <span>Actualizar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" dark v-on="on"
                                                    @click="handleClick(item, 'delete')">mdi-trash-can-outline</v-icon>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>

                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="w-auto" v-show="isOpenDialog">
            <DialogQuestions ref="dialogQuestions" @deleteBot="eliminarBot" />
        </div>
        <div class="w-auto" v-show="isOpenDialog">
            <DialogMessages ref="dialogMessages" @delete="closeAlert" />
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import Bot from "../../models/bot"
import DialogQuestions from '@/components/utils/DialogQuestionComponent.vue'
import DialogMessages from '@/components/utils/DialogMessagesComponent.vue'

export default {
    name: 'BotComponent',
    components: {
        DialogQuestions,
        DialogMessages
    },
    data() {
        return {
            title: 'BOTS',
            // Variables
            bot: new Bot("", "", "", ""),
            // Table
            headers: [
                { text: "Par", value: "bot.pair", align: "center" },
                { text: "Cantidad por Trade", value: "bot.quantity", align: "center" },
                { text: "Cantidad Bot", value: "bot.amount", align: "center" },
                { text: "En marcha", value: "bot.started", align: "center" },
                { text: "% Compra", value: "bot.buy", align: "center" },
                { text: "% Venta", value: "bot.sell", align: "center" },
                { text: 'Acciones', value: 'actions', sortable: false, align: "center" }
            ],
            itemsTable: [],
            search: '',
            // Shows
            isOpenDialog: false,
            content: "",
            message: "",
            successful: false

        };
    },
    computed: {},
    created() { },
    mounted() {
        this.prepareData()
    },
    methods: {
        prepareData() {
            UserService.getListBots().then(
                response => {
                    if (response.data !== '') {
                        this.itemsTable = response.data;
                    }
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        handleClick(value, action) {
            switch (action) {
                case 'orders':
                    this.$router.push('/orders/' + value.bot.id);
                    break;
                case 'update':
                    this.$router.push('/bot_save/?idSuscripcion=' + value.id);
                    break;
                case 'delete':
                    this.bot = value.bot
                    this.openDialogQuestion()
                    break;
            }
        },
        openDialogQuestion() {
            this.$refs.dialogQuestions.openModal()
            this.$refs.dialogQuestions.component = 'DELETE_BOT'
            this.$refs.dialogQuestions.tituloDialog = 'Eliminar Bot'
            this.$refs.dialogQuestions.question = '¿ Desea realmente eliminar el bot ?'
        },
        eliminarBot() {
            UserService.deleteBot(this.bot).then(
                response => {
                    this.content = response
                    setTimeout(() => (this.responseDelete('success', this.content)), 2000)
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setTimeout(() => (this.responseDelete('error', this.content)), 2000)
                }
            );
        },
        responseDelete(response, msg) {
            this.$refs.dialogMessages.openModal()
            this.$refs.dialogMessages.component = 'ELIMINAR BOT'
            this.$refs.dialogMessages.tituloDialog = 'ELIMINAR BOT'
            this.$refs.dialogMessages.msgError = msg
            this.$refs.dialogMessages.isShow = true
            if (response === 'success') {
                this.$refs.dialogMessages.types = 'success'
                this.successful = true
            } else {
                this.$refs.dialogMessages.types = 'error'
                this.successful = false
            }
        },
        closeAlert() {
            if (this.successful) {
                this.successful = false
                this.itemsTable = []
                this.prepareData()
            }
        }
    }
}
</script>