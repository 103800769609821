<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <validation-observer ref="observer" v-slot="{ invalid }" name="observer">
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-row no-gutters style="flex-wrap: nowrap;">
                                            <v-col cols="2" class="flex-grow-0 flex-shrink-0">
                                            </v-col>
                                            <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                                <!-- <div v-if="isBot"> -->
                                                <div v-show="isBot">
                                                    <validation-provider v-slot="{ errors }" name="Exchange"
                                                        rules="required">
                                                        <v-text-field v-model="exchange.exchange"
                                                            :error-messages="errors" label="Exchange" readonly
                                                            disabled></v-text-field>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Riesgo"
                                                        rules="required">
                                                        <v-text-field v-model="riesgo.riesgo" :error-messages="errors"
                                                            label="Riesgo" readonly disabled></v-text-field>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Par"
                                                        rules="required">
                                                        <v-text-field v-model="pair" :error-messages="errors"
                                                            label="Par" readonly disabled></v-text-field>
                                                    </validation-provider>
                                                </div>
                                                <!-- <div v-if="!isBot"> -->
                                                <div v-show="!isBot">
                                                    <validation-provider v-slot="{ errors }" name="Exchange"
                                                        rules="required">
                                                        <v-select v-model="exchange" :items="itemsExchange"
                                                            item-text="exchange" item-value="id"
                                                            label="Selecciona el Exchange" persistent-hint return-object
                                                            single-line></v-select>
                                                        <div class="invalid-feedback">
                                                            <p style="color:red">{{ errors[0] }}</p>
                                                        </div>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Riesgo"
                                                        rules="required">
                                                        <v-select v-model="riesgo" :items="itemsRiesgo"
                                                            item-text="riesgo" item-value="id"
                                                            label="Selecciona el tipo de Riesgo"
                                                            @change="onChange($event)" persistent-hint return-object
                                                            single-line></v-select>
                                                        <div class="invalid-feedback">
                                                            <p style="color:red">{{ errors[0] }}</p>
                                                        </div>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Par"
                                                        rules="required">
                                                        <v-select return-object v-model="pair" :items="itemsSymbols"
                                                            label="Pares"></v-select>
                                                        <div name="pares" class="invalid-feedback">
                                                            <p style="color:red">{{ errors[0] }}</p>
                                                        </div>
                                                    </validation-provider>
                                                </div>
                                                <validation-provider v-slot="{ errors }" name="Total Bot"
                                                    rules="required">
                                                    <v-text-field v-model="amount" :error-messages="errors"
                                                        label="Total Bot" required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Cantidad"
                                                    rules="required">
                                                    <v-text-field v-model="quantity" :error-messages="errors"
                                                        label="Cantidad por orden" required></v-text-field>
                                                </validation-provider>
                                                <!-- // 11012025 Se añade nueva mejora para determinar compras y ventas INICIO -->
                                                <v-row no-gutters style="flex-wrap: nowrap;">
                                                    <v-col cols="5" class="flex-grow-0 flex-shrink-0">
                                                        <validation-provider v-slot="{ errors }"
                                                            name="Porcentaje Compra" rules="required">
                                                            <v-text-field v-model="buy" :error-messages="errors"
                                                                label="% Compra" required></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="2" class="flex-grow-0 flex-shrink-0">
                                                    </v-col>
                                                    <v-col cols="5" class="flex-grow-0 flex-shrink-0">
                                                        <validation-provider v-slot="{ errors }" name="Porcentaje Venta"
                                                            rules="required">
                                                            <v-text-field v-model="sell" :error-messages="errors"
                                                                label="% Venta" required></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                                <!-- // 11012025 Se añade nueva mejora para determinar compras y ventas FIN -->
                                                <!-- // 18012025 Se añade nueva mejora para añadir interes compuesto INICIO -->
                                                <div v-show="isPremium">
                                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                                        <label class="checkbox"
                                                            style="margin-top: 0px;margin-right: 15px;">
                                                            <input v-model="intCompuesto" name="intCompuesto"
                                                                type="checkbox" :error-messages="errors">
                                                        </label>
                                                        <p style="margin-top: 3px;">Interes compuesto.</p>
                                                    </v-row>
                                                </div>
                                                <!-- // 18012025 Se añade nueva mejora para añadir interes compuesto FIN -->
                                                <v-row no-gutters style="flex-wrap: nowrap;">
                                                    <v-subheader>Gestión Riesgo (Opcional)</v-subheader>
                                                    <v-btn class="ma-2" text icon @click="opcionalGestionRiesgo">
                                                        <v-icon>mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </v-row>
                                                <v-row v-show="optionalShow" no-gutters style="flex-wrap: nowrap;">
                                                    <v-text-field v-model="gest_riesgo" :error-messages="errors"
                                                        hint="Gestión Riesgo" required></v-text-field>
                                                </v-row>
                                                <br>
                                                <v-row no-gutters>
                                                    <v-col cols="4" xs="0" sm="0" md="5">
                                                    </v-col>
                                                    <v-col cols="4" xs="2" sm="2" md="2">
                                                        <v-row no-gutters justify="center">
                                                            <v-btn :disabled="invalid" color="success"
                                                                @click="prepareData">Guardar</v-btn>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <br>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </validation-observer>
                            </v-col>
                        </v-row>
                        <br>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="w-auto" v-show="isOpenDialog">
            <DialogMessages ref="dialogMessages" @save="closeAlert" />
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus"
import UserService from '../../services/user.service'
import User from '../../models/user'
import Bot from "../../models/bot"
import Suscripcion from "@/models/suscripcion"
import Client from "@/models/client"
import DialogMessages from '@/components/utils/DialogMessagesComponent.vue'
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'


setInteractionMode('eager')

extend('required', {
    ...required,
    message: 'El/La {_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: 'El/La {_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

export default {
    name: 'NewBotComponent',
    components: {
        ValidationProvider,
        ValidationObserver,
        DialogMessages
    },
    data() {
        return {
            // Variables Pantalla
            title: 'CREA TU BOT',
            // Objetos
            user: new User(),
            client: new Client(),
            bot: new Bot("", "", "", ""),
            idBot: '',
            suscripcion: new Suscripcion(''),
            idSuscripcion: '',
            // Items
            itemsExchange: [],
            itemsRiesgo: [],
            itemsSymbols: [],
            // Models
            valid: '',
            exchange: '',
            riesgo: '',
            pair: '',
            amount: '',
            quantity: '',
            // 11012025 Se añade nueva mejora para determinar compras y ventas INICIO
            // percent: '',
            buy: '',
            sell: '',
            // 11012025 Se añade nueva mejora para determinar compras y ventas FIN
            // 18012025 Se añade nueva mejora para añadir interes compuesto INICIO
            intCompuesto: false,
            isPremium: false,
            // 18012025 Se añade nueva mejora para añadir interes compuesto FIN
            gest_riesgo: '',
            errors: '',
            // Variables
            content: '',
            message: '',
            // Condicionales
            isBot: false,
            optionalShow: false,
            successful: false,
            isOpenDialog: false           
        };
    },
    computed: {
        computedObserver() {
            return this.$refs.observer;
        }
    },
    created() {
        if (this.$store.state.user === "") {
            EventBus.dispatch("logout");
            this.$store.dispatch("auth/logout");
            this.$router.push("/");
        } else {
            UserService.getClients().then(
                response => {
                    if (response.data !== '') {
                        for (let i = 0; i < response.data.length; i++) {
                            let aux = { 'id': response.data[i]['id'], 'exchange': response.data[i]['exchange']['exchange'] }
                            this.itemsExchange.push(aux);
                        }
                    }
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }
    },
    mounted() {
        // let keys = Object.keys(this.$route.query)
        let values = Object.values(this.$route.query)
        this.idSuscripcion = values[0]
        this.chargeSuscripcion()

        UserService.getRiesgos().then(
            response => {
                for (let i = 0; i < response.data.length; i++) {
                    let aux = { 'id': response.data[i]['id'], 'riesgo': response.data[i]['riesgo'] }
                    this.itemsRiesgo.push(aux);
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout")
                }
            }
        )
    },
    methods: {
        chargeSuscripcion() {
            UserService.getSuscripcion(this.idSuscripcion).then(
                response => {
                    this.suscripcion = response.data
                    // console.log(this.suscripcion.tipoSuscripcion)
                    // 18012025 Se añade nueva mejora para añadir interes compuesto INICIO
                    if (this.suscripcion.tipoSuscripcion.nombre.includes('Premium')) {
                        // console.log('ES PREMIUM')
                        this.isPremium = true
                    }
                    // 18012025 Se añade nueva mejora para añadir interes compuesto FIN
                    if (this.suscripcion.bot === null) {
                        this.title = 'CREA TU BOT'
                        this.isBot = false
                    } else {
                        this.title = 'ACTUALIZA TU BOT'
                        this.isBot = true
                        this.chargeBot(this.suscripcion.bot.id)
                    }
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()

                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout")
                    }
                }
            );
        },
        chargeBot(value) {
            UserService.getBot(value).then(
                response => {
                    // console.log(response.data)
                    this.content = response.data
                    this.idBot = this.content.id
                    this.client.id = this.content.client.id
                    this.exchange = this.content.client.exchange
                    UserService.getListByRiesgo(this.content.riesgo.riesgo).then(
                        response => {
                            this.itemsSymbols = response.data
                        },
                        error => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            this.msgError = error.response.data
                            this.types = 'error'
                            this.isShow = true
                        }
                    );
                    this.riesgo = this.content.riesgo
                    this.pair = this.content.pair
                    this.amount = this.content.amount
                    this.quantity = this.content.quantity
                    // 11012025 Se añade nueva mejora para determinar compras y ventas INICIO
                    // this.percent = this.content.percent
                    this.buy = this.content.buy
                    this.sell = this.content.sell
                    // 11012025 Se añade nueva mejora para determinar compras y ventas FIN
                    this.gest_riesgo = this.content.gest_riesgo
                    this.intCompuesto = this.content.intCompuesto
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout")
                    }
                }
            );
        },
        onChange(value) {
            UserService.getListByRiesgo(this.exchange.exchange, value.riesgo).then(
                response => {
                    this.itemsSymbols = response.data
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setTimeout(() => (this.responseGestionRiesgo('error', error.response.data)), 2000)
                }
            );
        },
        prepareData() {
            this.submitted = true
            this.bot.pair = this.pair
            this.bot.user = this.suscripcion.user
            this.bot.quantity = this.quantity
            this.bot.amount = this.amount
            // 11012025 Se añade nueva mejora para determinar compras y ventas INICIO
            // this.bot.percent = this.percent
            this.bot.buy = this.buy
            this.bot.sell = this.sell
            // 11012025 Se añade nueva mejora para determinar compras y ventas FIN
            this.bot.riesgo = this.riesgo
            this.bot.client = this.client
            if (this.bot.gest_riesgo !== '' && this.bot.gest_riesgo !== undefined && this.bot.gest_riesgo !== null) {
                this.bot.gest_riesgo = this.gest_riesgo
            } else {
                this.bot.gest_riesgo = 0
            }
            this.bot.intCompuesto = this.intCompuesto
            if (this.suscripcion.bot === null) {
                this.client.id = this.exchange['id']
                this.suscripcion.bot = this.bot
                this.newBot()
            } else {
                this.bot.id = this.idBot
                this.saveBot()
            }
        },
        newBot() {
            UserService.postSaveBot(this.suscripcion).then(
                response => {
                    this.content = response
                    setTimeout(() => (this.responseSave('success', this.content)), 2000)
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setTimeout(() => (this.responseSave('error', error.response.data)), 2000)
                }
            );
        },
        saveBot() {
            // console.log(this.bot)
            UserService.postUpdateBot(this.bot).then(
                response => {
                    this.content = response
                    setTimeout(() => (this.responseSave('success', this.content)), 2000)
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString()
                    setTimeout(() => (this.responseSave('error', error.response.data)), 2000)
                }
            );
        },
        responseSave(response, msg) {
            this.$refs.dialogMessages.openModal()
            this.$refs.dialogMessages.component = 'SAVE BOT'
            this.$refs.dialogMessages.tituloDialog = 'GUARDAR BOT'
            this.$refs.dialogMessages.msgError = msg
            this.$refs.dialogMessages.isShow = true
            if (response === 'success') {
                this.$refs.dialogMessages.types = 'success'
                this.successful = true
            } else {
                this.$refs.dialogMessages.types = 'error'
                this.successful = false
            }
        },
        responseGestionRiesgo(response, msg) {
            this.$refs.dialogMessages.openModal()
            this.$refs.dialogMessages.component = 'GESTION RIESGO'
            this.$refs.dialogMessages.tituloDialog = 'PARES GESTION DE RIESGO'
            this.$refs.dialogMessages.msgError = msg
            this.$refs.dialogMessages.isShow = true
            if (response === 'success') {
                this.$refs.dialogMessages.types = 'success'
                this.successful = true
            } else {
                this.$refs.dialogMessages.types = 'error'
                this.successful = false
            }
        },
        closeAlert() {
            if (this.successful) {
                this.$router.push('/bot');
            }
        },
        opcionalGestionRiesgo() {
            this.optionalShow = !this.optionalShow
        },
    }
}
</script>