<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <div class="d-flex flex-column mb-6 bg-surface-variant">
                <v-row no-gutters style="flex-wrap: nowrap;">
                    <v-col>
                        <v-card elevation="2" outlined shaped>
                            <v-row justify="center">
                                <v-card-title class="text-h5">{{ title }}</v-card-title>
                            </v-row>
                            <v-divider class="mx-4"></v-divider>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <br>
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="2" xs="0" sm="0" md="3" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="8" xs="8" sm="8" md="6" class="flex-grow-0 flex-shrink-0">
                                            <validation-provider v-slot="{ errors }" name="Nombre"
                                                rules="required|max:10">
                                                <v-text-field v-model="name" :counter="10" :error-messages="errors"
                                                    label="Nombre" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="Apellidos"
                                                rules="required|max:20">
                                                <v-text-field v-model="lastName" :counter="20" :error-messages="errors"
                                                    label="Apellidos" required></v-text-field>
                                            </validation-provider>
                                            <validation-provider v-slot="{ errors }" name="email"
                                                rules="required|email">
                                                <v-text-field v-model="email" :error-messages="errors" label="E-mail"
                                                    required readonly></v-text-field>
                                            </validation-provider>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="4" xs="3" sm="3" md="4">
                                        </v-col>
                                        <v-col cols="4" xs="3" sm="3" md="4">
                                            <v-row no-gutters justify="center">
                                                <v-btn :disabled="invalid" color="success" @click="saveUser">
                                                    Guardar
                                                </v-btn>
                                            </v-row>
                                            <br>
                                            <v-row no-gutters justify="center">
                                                <v-btn :disabled="invalid" color="success"
                                                    @click="dialogPass = !dialogPass">
                                                    Cambiar Password
                                                </v-btn>
                                            </v-row>
                                            <br>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-col>
                </v-row>
                <v-fade-transition hide-on-leave>
                    <v-card v-if="dialogPass" elevation="2" outlined shaped title="Cambio de contraseña">
                        <template v-slot:append>
                            <v-btn icon="$close" variant="text" @click="dialogPass = false"></v-btn>
                        </template>
                        <v-divider></v-divider>
                        <div class="py-12 text-center">
                            <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="128"></v-icon>
                            <div class="text-h4 font-weight-bold">Cambie su contraseña</div>
                            <v-row>
                                <v-col cols="2"></v-col>
                                <v-col cols="8"><v-text-field :type="show4 ? 'text' : 'password'" v-model="old_password"
                                        :error-messages="errors" label="Password" required></v-text-field>
                                    <validation-provider vid="confirm" v-slot="{ errors }" name="Nueva Contraseña"
                                        rules="required|pass">
                                        <v-text-field :type="show4 ? 'text' : 'password'" v-model="new_password"
                                            :error-messages="errors" label="Nueva Contraseña" required></v-text-field>
                                    </validation-provider>
                                    <validation-provider v-slot="{ errors }" name="Confirmacion Password"
                                        rules="required|confirmar:@confirm">
                                        <v-text-field type="password" v-model="password1" :error-messages="errors"
                                            label="Confirme Nueva Contraseña" required></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </div>
                        <v-divider></v-divider>
                        <div class="pa-4 text-end">
                            <v-row>
                                <v-col cols="1"></v-col>
                                <v-col cols="4">
                                    <v-btn class="text-none" color="success" min-width="92" variant="outlined" rounded
                                        @click="changePass">
                                        Guardar
                                    </v-btn>
                                </v-col>
                                <v-col cols="1"></v-col>
                                <v-col cols="4">
                                    <v-btn class="text-none" color="medium-emphasis" min-width="92" variant="outlined"
                                        rounded @click="dialogPass = false">
                                        Cerrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-fade-transition>
                <br>
            </div>
        </div>
        <div class="w-auto" v-show="isOpenDialog">
            <DialogMessages ref="dialogMessages" @save="closeAlert" />
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus"
import UserService from '../../services/user.service'
import User from '../../models/user'
import DialogMessages from '@/components/utils/DialogMessagesComponent.vue'
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: '{_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

extend("pass", {
    params: ["otherValue"],
    validate: (value) => {
        if (
            value === null ||
            value.length < 6 ||
            !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).+$/.test(value)
        ) {
            return false;
        }
        return true;
    },
    message:
        "La contraseña no cumple los requisitos."
});

extend('confirmar', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Las contraseñas no coinciden.'
});

export default {
    name: 'ProfileComponent',
    components: {
        ValidationProvider,
        ValidationObserver,
        DialogMessages
    },
    data() {
        return {
            // Variables pantalla
            title: 'PERFIL',
            // Objetos
            user: new User('', '', ''),
            // Models
            valid: '',
            name: '',
            lastName: '',
            email: '',
            old_password: '',
            new_password: '',
            password1: '',
            // Variables
            content: '',
            errors: '',
            show4: '',
            // Condicionales
            dialogPass: false,
            isOpenDialog: false,
            successful: false,            
        };
    },
    computed: {
        currentUser() {
            return this.user
        }
    },
    mounted() {
        UserService.getUserProfile().then(
            response => {
                this.user.email = response.data.email;
                this.user.username = response.data.username;
                this.content = response.data
                this.name = this.content.name
                this.lastName = this.content.lastName
                this.email = this.content.email
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout")
                }
            }
        );
    },
    methods: {
        async saveUser() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return;
                }
                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    try {
                        this.user.name = this.name
                        this.user.lastName = this.lastName
                        this.user.email = this.email

                        UserService.saveUser(this.user).then(
                            response => {
                                setTimeout(() => (this.responseSave('success', response)), 2000)
                            },
                            error => {
                                this.content =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                setTimeout(() => (this.responseSave('error', error.response.data)), 2000)
                            }
                        );
                    } catch (error) {
                        setTimeout(() => (this.responseSave('error', error.response.data)), 2000)
                    }
                });
            });
        },
        responseSave(response, msg) {
            this.$refs.dialogMessages.openModal()
            this.$refs.dialogMessages.component = 'GUARDAR PERFIL'
            this.$refs.dialogMessages.tituloDialog = 'GUARDAR PERFIL'
            this.$refs.dialogMessages.msgError = msg
            this.$refs.dialogMessages.isShow = true
            if (response === 'success') {
                this.$refs.dialogMessages.types = 'success'
                this.successful = true
            } else {
                this.$refs.dialogMessages.types = 'error'
                this.successful = false
            }
        },
        closeAlert() {
            if (this.successful) {
                this.$router.push("/home")
            }
        },
        changePass() {
            UserService.changePass(this.$store.state.auth.user.email, this.old_password, this.new_password).then(
                response => {
                    setTimeout(() => (this.responseSave('success', response)), 2000)
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setTimeout(() => (this.responseSave('error', error.response.data)), 2000)
                }
            );
        }
    }
}
</script>